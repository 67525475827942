.headerContainer {
  position: relative;
  overflow: hidden;

  & > div {
    padding: 1rem;
  }
}

.standardWidget {
  & > * {
    transform: scale(0.66);
    transform-origin: center;
  }
}

.scaleWidgetContainer {
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  & > * {
    transform: scale(0.5);
  }
  border-radius: 0.25rem 0.25rem 0 0;
}

.descriptionParagraph {
  padding-block-start: var(--betterplace-spacing-200);
}

.image {
  object-fit: cover;
}
