.editButtonContainer {
  width: fit-content;
}

.inputContainer {
  > div {
    flex-grow: 1;
  }
}

.answersLegend {
  padding-block-end: var(--betterplace-spacing-100);
}
