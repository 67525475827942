.formDebug {
  margin-block: var(--betterplace-spacing-250);
}

.jsErrors {
  margin-block: var(--betterplace-spacing-250);
}

.valueCopiedToast {
  position: fixed;
  top: 0;
  right: 0;
  border: var(--betterplace-border-width-default);
  border-radius: var(--betterplace-border-radius-200);
  background-color: var(--betterplace-color-fg-danger);
  color: var(--betterplace-color-fg-light-primary);
  padding: var(--betterplace-spacing-200);
}

.fakeButtonsList {
  margin-block-end: var(--betterplace-spacing-250);
}

.fakeButtonsHeading {
  margin-block: 0 var(--betterplace-border-radius-300);
}

.fakeButtonContainer {
  display: flex;
  align-items: center;
  gap: var(--betterplace-spacing-200);
}

.debugTable {
  margin-block-end: var(--betterplace-spacing-300);
  width: 100%;

  th,
  td {
    border-top-width: var(--betterplace-border-width-default);
    border-color: rgb(238 238 238 / 100%);
    padding: var(--betterplace-spacing-250);
    vertical-align: top;
  }
}

.debugTableStriped {
  tbody {
    tr:nth-of-type(odd) {
      background-color: rgb(0 0 0 / 5%);
    }
  }
}

.genericAdminBox {
  padding: var(--betterplace-spacing-250);
  background: #28638c;
  color: white;
  overflow: auto;

  code {
    font-size: 87.5%;
    color: black;
    word-wrap: break-word;

    font-family: Monaco, Menlo, Consolas, 'Courier New', monospace;

    background: #eeeeee;
    padding: 2px 4px;
    border-radius: 4px;
  }

  .helpInline {
    color: white;
    background: transparent;
    padding: 0 5px;
  }

  > *:first-child {
    margin-top: 0;
  }

  > p:last-child {
    margin-bottom: 0;
  }

  table {
    border: 0.5px solid;
    border-collapse: collapse;

    td,
    th {
      border: 0.5px solid;
      padding: 0.5rem;
    }
  }

  pre,
  span {
    color: inherit !important;
  }
}

.genericAdminBox.forPlatformAdmins {
  background-color: #4d1a43;
}
