.outerContainer {
  padding: var(--betterplace-spacing-450) var(--betterplace-spacing-200);
  container-type: inline-size;
}

@container (min-width: env(--betterplace-measure-body-text)) {
  .container {
    align-items: center;
    text-align: center;
  }
}
