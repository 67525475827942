.saveButton {
  align-self: flex-end;
}

.allAnswersContainer {
  width: 100%;
}

.singleAnswerContainer div {
  flex: 1;
}

.addAnswerButton {
  align-self: flex-start;
}

.answersLegend {
  padding-block-end: var(--betterplace-spacing-100);
}

.formHeading {
  height: 2.9rem;
}
