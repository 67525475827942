.reasonsWrapper {
  position: relative;
  display: flex;
  align-items: center;

  :global(.swiper) {
    overflow: hidden;
  }
}

.reason {
  border-style: solid;
  border-width: 1px;
  border-color: rgb(238 238 238);
  background-color: rgb(255 255 255);
  border-radius: 0.25rem;
  padding: 0.75rem;
  font-size: 0.875rem;
  line-height: 1.25rem;
}
