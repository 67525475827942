.amountSumContainer {
  margin-block: var(--betterplace-spacing-300) var(--betterplace-spacing-500);
  text-align: center;
}

.sumLabelContainer {
  font: var(--betterplace-typography-functional-text-lg-important);
  display: block;
}

.sumLabel {
  margin-inline-end: var(--betterplace-spacing-100);
}

.thankYouText {
  color: var(--betterplace-color-fg-content-secondary);
}

@mixin desktop {
  .amountSumContainer {
    text-align: left;
  }

  .sumLabelContainer {
    display: inline;
    margin-inline-end: var(--betterplace-spacing-250);
  }
}
