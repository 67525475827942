.linkWrapper {
  display: block;
  position: relative;
  flex: 1;
  overflow-y: visible;
}

.link {
  position: absolute;
  margin-block-start: 0.18rem;
  right: 0;
  display: inline-block;
  text-align: right;
}
