.container {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  margin-left: -10px;
  margin-right: -10px;

  @mixin desktop {
    background-color: var(--betterplace-color-fg-content-primary);
  }

  @mixin mobile {
    height: auto;
    background-color: var(--betterplace-color-bg-light);
    padding-top: 20px;
    justify-content: flex-start;
  }
}

.image {
  @mixin desktop {
    width: 100%;
    display: block;
    opacity: var(--betterplace-opacity-md);
  }

  @mixin mobile {
    display: none;
  }
}

.heading {
  margin: 0 1.25rem;

  @mixin desktop {
    position: absolute;
    z-index: 20;
    color: var(--betterplace-color-fg-light-primary) !important;
    text-align: center;
    left: 0;
    right: 0;
  }

  @mixin mobile {
    color: var(--betterplace-color-fg-content-primary) !important;
  }
}
