.fieldset {
  container-type: inline-size;
  margin-block: var(--betterplace-spacing-300);
}

.headingMargin {
  margin-block-end: var(--betterplace-spacing-200);
}

.radio {
  display: inline-block;
}
