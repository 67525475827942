.container {
  width: 100%;
  height: auto;
}

.inputSkeleton {
  flex: 0 1 390px;
  flex-wrap: nowrap;
  align-items: flex-end;
  font: var(--betterplace-typography-label-sm);

  & > div:first-of-type {
    flex: 0 1 240px;
  }

  & > div:last-of-type {
    flex: 0 1 140px;
  }
}

.actionSkeleton {
  flex: 0 1 280px;
}

@mixin mobile {
  .actionSkeleton {
    flex-basis: 100%;
  }
}
