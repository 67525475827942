.layout {
  margin-bottom: var(--betterplace-spacing-450);
}

.main {
  max-inline-size: var(--betterplace-sizing-fix-content-narrow);
}

.devToolbar {
  background-color: var(--betterplace-color-bg-secondary);
  padding: var(--betterplace-spacing-300);
}
