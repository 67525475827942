.matchingBanner {
  margin-block-start: var(--betterplace-sizing-300);
}

.heading {
  margin-block-start: 0;
}

.radioButton {
  inline-size: 100%;
}
