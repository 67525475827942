@mixin mobile {
  .fullWidth > * {
    flex: 1 0 100%;
    display: flex;
    flex-direction: column;
  }
}

@mixin mobile {
  .skeletonContainer > div {
    flex: 1 0 100%;
  }

  .fontPickerSkeleton,
  .fontMeasurementSelectorSkeleton {
    width: 100% !important;
  }
}

.skeletonContainer {
  font: var(--betterplace-typography-label-sm);
}

.fontPickerSkeleton {
  width: 200px;
}

.fontMeasurementSelectorSkeleton {
  width: 109px;
}
