/* stylelint-disable selector-class-pattern */
.label {
  margin-block: var(--betterplace-spacing-200);
}

.stripeField {
  margin-block-end: var(--betterplace-spacing-400);

  :global(.StripeElement) {
    padding: var(--betterplace-spacing-250) var(--betterplace-spacing-300);
    background: var(--betterplace-color-bg-light);
    border: var(--betterplace-border-default);
    border-radius: var(--betterplace-border-radius-200);
    color: var(--betterplace-color-fg-content-primary);
    font: var(--betterplace-typography-functional-text-md-default) !important;
    inline-size: 100%;

    &:hover {
      box-shadow: var(--betterplace-shadow-floating-low);
    }
  }

  :global(.StripeElement--focus) {
    outline: var(--betterplace-border-focused);
    outline-offset: var(--betterplace-spacing-50);
  }

  :global(.StripeElement--invalid) {
    border: var(--betterplace-border-danger);
  }
}

.stripeFieldError {
  font: var(--betterplace-typography-functional-text-sm-important-narrow);
  color: var(--betterplace-color-fg-danger);

  /* We don't use a token because we assume we only need this in-between value for the errors and warnings underneath inputs. */
  margin-block-start: 0.35rem;
}

.stripeNotLoadedMessage {
  margin-block-end: var(--betterplace-spacing-400);
}
