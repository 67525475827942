.cookieBannerContainer {
  background-color: var(--betterplace-color-accent-secondary);
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1038;
  height: auto;
  color: white;
  box-shadow: var(--betterplace-shadow-floating-medium-upwards);
  padding: 2rem 0;
}

.cookieContentContainer {
  max-width: 1440px;
  margin: 0 auto;

  .row {
    display: flex;
    flex-wrap: wrap;
  }

  .textContent {
    flex: 12 0 80%;
    max-width: 75%;
    padding: 0 1rem;

    @mixin mobile {
      max-width: 100%;
    }
  }

  .logoWrapper {
    flex: 0 0 16%;
    max-width: 16%;
    display: none;

    @mixin desktop {
      display: block;

      .logo {
        display: flex;
        align-self: center;
        justify-content: center;
      }
    }
  }
}

.cookieBannerButtonRow {
  display: flex;
  justify-content: space-between;
  padding-top: 1rem;

  @mixin mobile {
    flex-direction: column;
  }
}

.simulatedLink {
  color: white;
  border: none;
  background: none;
  text-decoration: none;
  cursor: pointer;

  padding: 0;
  font: var(--betterplace-typography-label-xs-secondary);

  &:hover,
  &focus {
    color: white;
    border: none;
    background: none;
    text-decoration: underline;
  }
}

.cookieActionButtonsContainer {
  flex-basis: 50%;
  display: flex;
  justify-content: space-between;
  gap: 12px;

  @mixin mobile {
    flex-direction: column;
  }

  button {
    flex-grow: 1;
    flex-basis: 100%;
  }
}

.acceptButtonContent {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
}

.informationDetailsContent {
  h3 {
    font-size: 1rem;
    line-height: 1.5rem;
  }
}

.moreInformationWrapper {
  align-self: center;

  @mixin mobile {
    margin-block-end: var(--betterplace-spacing-200);
  }
}

.acceptButton:focus,
.acceptButton:focus-visible {
  outline-color: var(--betterplace-color-border-on-color);
}
