.container {
  padding: var(--betterplace-spacing-400);
  width: 100%;
  display: flex;
}

@mixin mobile {
  .fieldset {
    gap: var(--betterplace-spacing-200);
  }
}

.flexHeading {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.fullWidth {
  width: 100%;
}

/* :has(.container) {
  :nth-child(1) {
    z-index: 9;
  }

  :nth-child(2) {
    z-index: 8;
  }

  :nth-child(3) {
    z-index: 7;
  }

  :nth-child(4) {
    z-index: 6;
  }

  :nth-child(5) {
    z-index: 5;
  }

  :nth-child(6) {
    z-index: 4;
  }

  :nth-child(7) {
    z-index: 3;
  }

  :nth-child(8) {
    z-index: 2;
  }

  :nth-child(9) {
    z-index: 1;
  }
} */
