.taxDeductibilityNotice {
  font-weight: bold;
  text-align: center;
  color: var(--bp-iframe-color, var(--bp-iframe-color-default));
  border-block: var(--betterplace-border-light);
}

@media only screen and (min-width: 451px) {
  .taxDeductibilityNotice {
    margin-block-start: var(--betterplace-spacing-450);
    padding-block: var(--betterplace-spacing-450);
  }
}

@media only screen and (max-width: 450px) {
  .taxDeductibilityNotice {
    margin-block-start: var(--betterplace-spacing-200);
    padding-block: var(--betterplace-spacing-200);
  }
}
