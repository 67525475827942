.webhookUrlInput {
  text-overflow: ellipsis;
  flex-grow: 2;
  width: 100%;
  text-wrap: wrap;
}

.webhookUrlSaveButtonContainer {
  justify-content: flex-end;
  margin-block-start: var(--betterplace-spacing-200);
}

/* Hack to align the button with the input */
@container (min-width: 32.3rem) {
  .webhookEditButtonContainer {
    /* This is necessary to align the button with the input */
    padding-block-start: 3.05rem;
    width: fit-content;
  }
}

.frame {
  background-color: var(--betterplace-color-bg-subtle);
  padding-block: var(--betterplace-spacing-200);
  padding-inline: var(--betterplace-spacing-300);
  overflow-wrap: anywhere;
}

.inputContainer {
  > div {
    flex-grow: 1;
    flex-shrink: 1;
    overflow-wrap: anywhere;
  }
}
