.donateFormModal {
  border-radius: 4px;
  margin-top: var(--betterplace-spacing-200);
  max-inline-size: calc(100% - var(--betterplace-spacing-300));

  @mixin desktop {
    max-inline-size: 500px;
    margin: 1rem auto;
  }
}

.donateFormModalWide {
  @mixin desktop {
    max-inline-size: 800px;
    margin-right: auto;
    margin-left: auto;
  }
}

.donateFormModalInnerWide {
  max-inline-size: fit-content;
}

.donateFormModalContent {
  padding-left: 1rem;
  padding-right: 1rem;
  padding-bottom: 1rem;

  @mixin desktop {
    padding-left: 2rem;
    padding-right: 2rem;
    padding-bottom: 2rem;
  }
}

.donateFormTitle {
  border-bottom: 1px solid #cccccc;
  text-align: center;
  justify-content: center;

  h2 {
    font-size: 1.5rem;
    margin-top: 1rem;
  }
}
