.mainHeading + div {
  max-width: var(--betterplace-measure-body-text);
  flex: 1;
}

.mainHeading {
  flex: 1;
  margin-block-end: var(--betterplace-spacing-200);
}

.xCloseButton {
  align-self: flex-start;
}

.headingContainer,
.messages {
  width: 100%;
}
