.explanationMoreInfoButton {
  margin-inline: var(--betterplace-spacing-100);
}

.closeModalButton {
  margin-block: var(--betterplace-spacing-100);
}

.codonationSelectionCell {
  padding-inline-start: 0 !important;
  border: 0;
}

.codonationAmountSumCell {
  text-align: right;
  vertical-align: middle !important;
  padding-inline-end: 0 !important;
  border: 0;
}

.cell {
  border: 0;
  padding: var(--betterplace-spacing-250);
}

.codonationAmountSumContent {
  font: var(--betterplace-typography-functional-text-md-important);
  white-space: nowrap;
}

.totalDonationAmountSumCell {
  text-align: right;
  padding-inline-end: 0 !important;
}

.row {
  border-bottom: var(--betterplace-border-light) !important;
}

.table {
  border-collapse: collapse;
  margin-bottom: var(--betterplace-spacing-200) !important;
  width: 100%;
  font: var(--betterplace-typography-functional-text-md-important);

  tbody td {
    vertical-align: top;
  }

  td:first-child {
    white-space: nowrap;
  }
}

.totalDonationAmountSumContent {
  font: var(--betterplace-typography-functional-text-lg-important);
}

.donationSummaryContainer {
  padding-block: var(--betterplace-spacing-200);
}
