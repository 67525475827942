.trustLogos {
  display: flex;
  gap: var(--betterplace-spacing-300);
  margin-block: var(--betterplace-spacing-250);
}

.trustLogo {
  height: 2.25rem;
  flex: 0;
}
