.progressFormLabel {
  font: var(--betterplace-typography-label-sm);
}

.volumeBackground {
  /* Overriding the focus outline background color for the slightly darker background of the Card component */
  &:focus-visible::-webkit-slider-thumb {
    box-shadow:
      0 0 0 var(--betterplace-spacing-50) var(--betterplace-color-bg-subtle),
      0 0 0 var(--betterplace-spacing-100) var(--betterplace-color-border-focus);
  }

  &:focus-visible::-moz-range-thumb {
    box-shadow:
      0 0 0 var(--betterplace-spacing-50) var(--betterplace-color-bg-subtle),
      0 0 0 var(--betterplace-spacing-100) var(--betterplace-color-border-focus);
  }
}
