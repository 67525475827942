.wrapper {
  margin-block-start: var(--betterplace-spacing-500);
  text-align: left;
  position: relative;
}

.iconWrapper {
  position: absolute;
  height: var(--betterplace-spacing-500);
  width: var(--betterplace-spacing-500);
  border-radius: 9999px;
  background-color: rgb(158 203 10 / 100%);
  display: flex;
  place-content: center center;
  top: 0;
  left: 50%;
  transform: translate(-50%, -50%);

  @mixin desktop {
    left: 0;
    transform: translateY(-50%);
  }
}

.icon {
  width: var(--betterplace-spacing-400);
}

.container {
  background-color: rgb(248 248 248);
  padding: 1rem;
  padding-top: calc(1rem + 24px);
}

.headline {
  margin: 0;
}

.subline {
  display: block;
  margin-top: 0.75rem;
  margin-bottom: 0.5rem;
  font-size: 0.875rem;
  line-height: 1.25rem;
  font-weight: 700;
}

@mixin mobile {
  .headline,
  .subline {
    text-align: center;
  }
}
