.headerContainer {
  position: relative;
  overflow: hidden;
  background-color: var(--betterplace-color-bg-dark);
  justify-content: center;
  color: var(--betterplace-color-bg-light);
}

.noMaxWidthButton {
  max-width: unset !important;
  flex: unset !important;
  inline-size: unset !important;
  flex-basis: unset !important;
}

.image {
  object-fit: cover;
}
