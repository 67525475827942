.directDepositCard {
  background-color: var(--betterplace-color-bg-attention);
  padding: var(--betterplace-spacing-300);
  border: var(--betterplace-border-default);
  border-radius: var(--betterplace-border-radius-200);
  border-color: var(--betterplace-color-border-attention);
  margin-block: var(--betterplace-spacing-200);
}

.directDepositText {
  margin-block-end: 0;
}
