.paymentMethodSelection {
  row-gap: 0;
  max-width: var(--betterplace-sizing-fix-content-narrow);
}

@container (max-width: env(--betterplace-measure-body-text)) {
  .paymentMethodSelection {
    display: block;
  }
}

.blockWrapper {
  padding-block: var(--betterplace-spacing-300);
}

.headingMargin {
  margin-block-end: var(--betterplace-spacing-200);
}

.donationAmountInput {
  width: 8.75rem;
}

.iFrameHeader {
  padding: var(--betterplace-spacing-200) var(--betterplace-spacing-400);
}

.iFrameHeaderInnerContainer {
  margin-inline: auto;
}

.iFrameInnerContainer {
  padding: var(--betterplace-spacing-400);
  margin-inline: auto;
}

.continueContainer {
  margin-block: var(--betterplace-spacing-400) var(--betterplace-spacing-250);
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.sslLogo {
  height: var(--betterplace-sizing-700);
}

.headline {
  color: white;
  text-align: center;
  padding: 12px 0;
  display: flex;
  justify-content: space-between;

  p {
    margin: 0;
  }

  :global(.line1) {
    font-size: 20px;
    font-weight: bold;
    line-height: 1.3;

    &:only-child {
      line-height: 2.3;
    }
  }

  :global(.line2) {
    font-size: 16px;
  }
}

.backButton {
  align-self: center;
  height: fit-content;
  padding: 0;
}

.headingContainer {
  flex-basis: 40%;
  flex-grow: 1;
}

.column {
  flex-grow: 1;
  flex-basis: 0;
  display: flex;
}
