.buttonContainer {
  bottom: var(--betterplace-spacing-200);
  gap: var(--betterplace-spacing-100);
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-end;
  justify-self: flex-end;
  container-type: inline-size;
}

.buttonContainer button,
.buttonContainer a {
  flex: 1;
  min-width: 13rem;
}

@mixin desktop {
  @container (width > 15.25rem) {
    .multipleButtons {
      button,
      a {
        max-width: 11rem;
      }
    }

    .buttonContainer {
      button,
      a {
        flex: 1;
        min-width: 7.5rem;
      }
    }
  }
}
