.learnMoreText {
  margin-block-start: 0;
}

.bannerContainer {
  display: flex;
  align-items: center;
  position: relative;
  color: var(--betterplace-color-fg-content-primary);
  background: var(--betterplace-color-bg-tertiary);
  margin-top: var(--betterplace-sizing-100);
  padding: 0.69rem 1rem 0.69rem 1.25rem;
  border-radius: var(--betterplace-border-radius-200);

  .gentleLink {
    font-weight: normal;
    text-decoration: underline;
    color: inherit;
  }

  ::before {
    content: '';
    width: 0;
    height: 0;
    position: absolute;
    border-left: 0.625rem solid transparent;
    border-right: 0.625rem solid transparent;
    border-top: 0.625rem solid transparent;
    border-bottom: 0.625rem solid var(--betterplace-color-bg-tertiary);
    left: 0;
    right: 0;
    margin-inline: auto;
    top: -1.2rem;
  }
}

@media only screen and (max-width: 450px) {
  .bannerContainer {
    min-height: var(--betterplace-sizing-700);
  }
}
