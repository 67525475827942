.container {
  > * + * {
    margin-top: 1rem;
  }
}

.logoContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  color: rgb(255 255 255);
  background-color: rgb(246 206 70);
  padding: 1rem;
  position: relative;
}

.logo {
  max-height: 45px;
  max-width: 45%;

  + .logo {
    flex-grow: 0;
    flex-shrink: 1;

    /* we need to make an exeption here, because the
    sparkassen logos contain their own transparent
    padding and we cannot easily change that */
    max-height: 80px;
    margin: -1rem -1rem -1rem auto;
  }
}

.buttonWrapper {
  display: flex;
}

.singleButtonWrapper {
  justify-content: flex-end;
}

.multipleButtonsWrapper {
  justify-content: space-between;
  gap: 1rem;
}

.infoMiniParagraph {
  margin-block-start: var(--betterplace-spacing-200);
}

.infoParagraph {
  margin-block-start: var(--betterplace-spacing-400);
}
