.blockWrapper {
  padding-block: var(--betterplace-spacing-300);
}

.headingMargin {
  margin-block-end: var(--betterplace-spacing-200);
}

.donationAmountInput {
  width: var(--betterplace-sizing-900);
}

.clientDonationSidebar {
  display: block;
  background-color: var(--betterplace-color-bg-light);
  padding-inline: var(--betterplace-spacing-250);
  padding-block: var(--betterplace-spacing-400);
  text-align: center;
  color: var(--betterplace-color-fg-mute);
  margin-top: 390px;
  z-index: 2;
  flex: 0 0 25%;
  max-width: 25%;
}

@mixin mobile {
  .clientDonationSidebar {
    display: none;
  }
}

.sidebarText {
  margin-block-end: var(--betterplace-spacing-400);
  padding-block-end: var(--betterplace-spacing-400);
  border-block-end: var(--betterplace-border-light);
}

.sslLogo {
  height: var(--betterplace-sizing-700);
}

.hundredPercentClaimText {
  margin-block: var(--betterplace-spacing-250) var(--betterplace-spacing-100);
}

.transactionCost {
  padding-block-end: var(--betterplace-spacing-400);
  margin-block-end: var(--betterplace-spacing-400);
  border-block-end: var(--betterplace-border-light);
}

.donationFormContent {
  padding: 2.5rem 1.875rem 3.125rem;
}

.container {
  width: 100%;
  padding-right: 0.625rem;
  padding-left: 0.625rem;
  margin-right: auto;
  margin-left: auto;
}

.row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -10px;
  margin-left: -10px;
  align-items: flex-start;
}

.column {
  position: relative;
  width: 100%;
  padding-right: 10px;
  padding-left: 10px;
}

.wrapperBackground {
  background-color: white;
}

.formFieldsContainer {
  display: flex;
  flex-direction: column;
}

@mixin mobile {
  .donationFormContent {
    padding: 1.875rem 0.625rem 1.25rem;
  }
}

@mixin desktop {
  .container {
    max-width: var(--betterplace-sizing-fix-content-total);
  }

  .wrapper {
    flex: 0 0 66.6667%;
    max-width: 66.6667%;
    margin-left: 4.1667%;
    margin-bottom: 100px;
    margin-top: 60px;
    box-shadow: 0 10px 20px rgb(0 0 0 / 50%);
    z-index: 3;
  }
}
