.loading {
  span {
    text-shadow: none;
  }
  opacity: 0.65;
}

.loading:hover {
  cursor: not-allowed;
}
