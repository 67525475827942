.container {
  min-width: 12.5rem;
  background: #ffc439;
  border-radius: var(--betterplace-border-radius-200);
  box-shadow: 2px 2px 3px grey;

  z-index: 1;
  position: relative;

  @mixin desktop {
    max-width: 18.75rem;
  }

  &:hover {
    background: #f6c439;
  }
}

.dummyPaypalButton {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.errorMessage {
  color: var(--betterplace-color-fg-danger);
}
