.blockWrapper {
  padding-block: var(--betterplace-spacing-300);
}

.headingMargin {
  margin-block-end: var(--betterplace-spacing-200);
}

.donationAmountInput {
  width: var(--betterplace-sizing-900);
}

.donationFormBackground {
  background-color: var(--betterplace-color-bg-secondary);
}

.donationFormContent {
  padding: 2.5rem 1.875rem 3.125rem;
}

.donationFormNotices {
  margin-inline: -10px;
}

.container {
  width: 100%;
  padding-right: 0.625rem;
  padding-left: 0.625rem;
  margin-right: auto;
  margin-left: auto;
}

.row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -10px;
  margin-left: -10px;
  justify-content: center;
}

.wrapper {
  background-color: white;
  position: relative;
  width: 100%;
  padding-right: 10px;
  padding-left: 10px;
}

.formFieldsContainer {
  display: flex;
  flex-direction: column;
}

@mixin mobile {
  .donationFormContent {
    padding: 1.875rem 0.625rem 1.25rem;
  }

  .donationFormNotices {
    margin-top: 30px;
  }
}

@mixin desktop {
  .container {
    max-width: var(--betterplace-sizing-fix-content-total);
  }

  .transparentHeaderSpace {
    padding-top: 65px;
  }

  .wrapper {
    flex: 0 0 66.6667%;
    max-width: 66.6667%;
    margin-bottom: 100px;
    margin-top: 60px;
  }
}
