.toggleContainer {
  width: 9rem;
}

.toggleSwitch {
  justify-content: flex-start;
  gap: var(--betterplace-spacing-200);
}

.toggleLabel {
  min-width: var(--betterplace-sizing-700);
}
